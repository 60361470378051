let items = [
  // {
  //   name: "Tableau de bord",
  //   active: false,
  //   url: "/agenda",
  //   icon: "fas fa-chart-line",
  //   class: "text-gray-600"
  // },
  {
    name: "MEDICAL",
    active: false,
    subs: [
      {
        name: "Calendrier",
        url: "/agenda",
        icon: "far fa-calendar-alt"
      },
      {
        name: "Configurer Calendrier",
        url: "/agenda/settings",
        icon: "fas fa-cogs"
      },
      {
        name: "Patientèle",
        url: "/patients",
        icon: "fas fa-id-card-alt"
      },
      {
        name: "Consultations",
        url: "/consultations",
        icon: "fas fa-stethoscope",
        isOnlyForOwner: true
      },
      {
        name: "Suivi médical augmenté",
        url: "/suivi/medical",
        icon: "fas fa-hospital-user"
      },
      {
        name: "Posologie",
        url: "/posologie",
        icon: "fas fa-flask"
      }
    ]
  },
  {
    name: "COMMUNICATION",
    active: false,
    class: "text-gray-600",
    subs: [
      // {
      //   name: "Réseaux sociaux",
      //   url: "/social-network",
      //   icon: "fas fa-bullhorn",
      //   class: "text-gray-600"
      // },
      // {
      //   soon: false,
      //   name: "Téléconseil",
      //   subText: "PROCHAINEMENT",
      //   icon: "fas fa-hand-holding-medical",
      //   url: "#",
      //   class: "text-gray-600"
      // },
      {
        name: "Messagerie",
        active: false,
        url: "/messages",
        icon: "far fa-comments",
        isOnlyForOwner: true
      },
      // {
      //   name: "Facturation",
      //   icon: "fas fa-user-lock",
      //   url: "/invoicing",
      //   isOnlyForOwner: true
      // },
    ]
  },

  {
    name: "ADMINISTRATION",
    active: false,
    subs: [
      {
        name: "Utilisateurs",
        subText: "PROCHAINEMENT",
        icon: "fas fa-user-lock",
        url: "/users",
        isOnlyForOwner: true
      },
      {
        name: "Support",
        subText: "Ask for Help",
        icon: "fas fa-info-circle",
        url: "/support"
      }
      /*{
         soon: false,
         name: "Facturation",
         subText: "PROCHAINEMENT",
         icon: "fas fa-receipt",
         url: "/factures"
       }*/
    ]
  }
];

let labo_items = [
  {
    name: "MEDICAL",
    active: false,
    subs: [
      {
        name: "Calendrier",
        url: "/labo/agenda",
        icon: "far fa-calendar-alt"
      },
      {
        name: "Configurer Calendrier",
        url: "/labo/agenda/settings",
        icon: "fas fa-cogs"
      },
      {
        name: "laboPatientèle",
        url: "/labo/patients",
        icon: "fas fa-id-card-alt"
      },
      {
        name: "Consultations",
        url: "/labo/consultations",
        icon: "fas fa-stethoscope",
        isOnlyForOwner: true
      },
      {
        name: "Radiologie",
        url: "/labo/scanner",
        icon: "fas fa-radiation",
        isOnlyForOwner: true
      }
    ]
  },
  {
    name: "ADMINISTRATION",
    active: false,
    subs: [
      {
        name: "Utilisateurs",
        subText: "PROCHAINEMENT",
        icon: "fas fa-user-lock",
        url: "/users",
        isOnlyForOwner: true
      },
      {
        name: "Support",
        subText: "Ask for Help",
        icon: "fas fa-info-circle",
        url: "/support"
      }
    ]
  }
];

let all_items = {
  items: items,
  labo_items: labo_items
};
export default all_items;
