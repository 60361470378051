<template>
  <div class="flex flex-col gap-y-2">
    <div class="flex justify-center items-center">
      <avatar-upload
        :param-file.sync="user.image"
        :active-upload="true"
        :reSize="true"
      ></avatar-upload>
    </div>
    <ValidationObserver ref="observer" class="grid gap-x-2 gap-y-3 grid-cols-2">
      <ValidationProvider
        name="lastName"
        rules="required"
        class="w-full"
        v-slot="{ errors }"
      >
        <dok-input
          size="lg"
          d-placeholder="Nom"
          d-type="text"
          :d-model.sync="user.lastName"
          v-model="user.lastName"
          label="Nom : "
          :custom-class="errors[0] ? 'border-red-500 w-full' : 'w-full'"
        >
        </dok-input>
      </ValidationProvider>
      <ValidationProvider
        name="firstName"
        rules="required"
        class="w-full"
        v-slot="{ errors }"
      >
        <dok-input
          size="lg"
          d-placeholder="Prénom"
          d-type="text"
          :d-model.sync="user.firstName"
          v-model="user.firstName"
          label="Prénom : "
          :custom-class="errors[0] ? 'border-red-500 w-full' : 'w-full'"
        >
        </dok-input>
      </ValidationProvider>
      <ValidationProvider
        name="CIN"
        rules="required"
        class="w-full"
        v-slot="{ errors }"
      >
        <dok-input
          size="lg"
          d-placeholder="CIN"
          d-type="text"
          :d-model.sync="user.cin"
          v-model="user.cin"
          label="CIN : "
          :custom-class="errors[0] ? 'border-red-500 w-full' : 'w-full'"
        >
        </dok-input>
      </ValidationProvider>
      <ValidationProvider
        name="Fonction"
        rules="required"
        class="w-full"
        v-slot="{ errors }"
      >
        <dok-input
          size="lg"
          d-placeholder="Fonction"
          d-type="text"
          :d-model.sync="user.job"
          v-model="user.job"
          label="Fonction : "
          :custom-class="errors[0] ? 'border-red-500 w-full' : 'w-full'"
        >
        </dok-input>
      </ValidationProvider>
      <ValidationProvider
        name="phone"
        rules="required|phoneNumber"
        class="w-full"
        v-slot="{ errors }"
      >
        <dok-input
          size="lg"
          d-placeholder="Téléphone"
          d-type="text"
          :d-model.sync="user.mobile"
          v-model="user.mobile"
          label="Téléphone : "
          :custom-class="errors[0] ? 'border-red-500 w-full' : 'w-full'"
        >
        </dok-input>
      </ValidationProvider>
      <ValidationProvider
        name="email"
        rules="required|email"
        class="w-full"
        v-slot="{ errors }"
      >
        <dok-input
          size="lg"
          d-placeholder="Email"
          d-type="email"
          :d-model.sync="user.email"
          v-model="user.email"
          label="Email : "
          :custom-class="errors[0] ? 'border-red-500 w-full' : 'w-full'"
        >
        </dok-input>
      </ValidationProvider>
      <dok-input
        size="lg"
        d-placeholder="Mot de passe"
        d-type="password"
        :d-model.sync="user.password"
        label="Mot de passe : "
      >
      </dok-input>
    </ValidationObserver>
    <section id="roles" class="my-2 flex flex-col gap-y-1">
      <hr class="border-0 border-b border-solid border-gray-200" />
      <h1 class="font-EffraM text-lg text-grayDok-sidebar">Roles:</h1>
      <div class="grid grid-cols-3 gap-x-2 gap-y-1">
        <div
          v-for="(role, index) in roles"
          :key="index"
          class="font-EffraR text-center text-black flex items-center gap-x-2"
        >
          <input
            type="checkbox"
            :id="`role-${index}`"
            class="
              form-checkbox
              text-grayDok-sidebar
              border border-solid border-grayDok-sidebar
              focus:outline-none
              cursor-pointer
            "
            v-model="user.roles"
            :value="role.name"
          />
          <label
            :for="`role-${index}`"
            class="text-base font-semibold cursor-pointer"
            >{{ role.name }}</label
          >
        </div>
      </div>
    </section>
    <dok-button size="md" @click.native="user._id ? onUpdate() : onCreate()"
      >Enregistrer</dok-button
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import rolesSide from "@/layouts/components/sidebar/items.js";
const avatarUpload = () => import("@/views/global-components/uploadAvatar.vue");

export default {
  components: { avatarUpload },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      roles: [],
    };
  },
  created() {
    let items = [];
    rolesSide.items.forEach((item) => {
      if (item.subs) {
        for (let i = 0; i < item.subs.length; i++) {
          if (item.subs[i].name != "Consultations") items.push(item.subs[i]);
        }
      }
    });
    this.roles = items;
  },
  methods: {
    ...mapActions("user", ["CREATE_USER", "UPDATE_USER"]),
    async onUpdate() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.user.role = "TEAM-MEMBER";
      const { ok, data, message } = await this.UPDATE_USER(this.user);
      if (ok) {
        this.$performTracking({
          data: {
            id: this.$auth.getUserId(),
            route: "USERS",
            action: "UPDATE",
            data: { _id: this.user._id },
          },
        });
        EventBus.$emit("close_modal_users");
      }
      EventBus.$emit("flashDok", {
        type: ok ? "success" : "errorr",
        message: ok ? "Modification effectuée avec succès" : message,
      });
    },

    async onCreate() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.user.role = "TEAM-MEMBER";
      const { ok, data, message } = await this.CREATE_USER(this.user);

      if (ok) {
        this.$performTracking({
          data: {
            id: this.$auth.getUserId(),
            route: "USERS",
            action: "CREATE",
            data: { _id: data._id || null },
          },
        });
        EventBus.$emit("close_modal_users");
      }

      EventBus.$emit("flashDok", {
        type: ok ? "success" : "errorr",
        message: ok ? "Utilisateur créé avec succès" : message,
      });
    },
  },

  beforeDestroy() {
    EventBus.$emit("Reset_Form_Users");
  },
};
</script>
